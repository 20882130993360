!function ($) {
    'use strict';
    $(function() {
        $('.generate-password').click(function(e) {
            e.preventDefault();
            var target = $(this).data('target');
            var charset = $(this).data('character-set');
            var length = $(this).data('character-length');
            var newPasswd = randString(charset,length);
            $(target).val(newPasswd);
        });
    });
}(window.jQuery);


function randString(charset, length){
    var dataSet = charset.split(',');  
    var possible = '';
    if($.inArray('a-z', dataSet) >= 0){
        possible += 'abcdefghijklmnopqrstuvwxyz';
    }
    if($.inArray('A-Z', dataSet) >= 0){
        possible += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if($.inArray('0-9', dataSet) >= 0){
        possible += '0123456789';
    }
    if($.inArray('#', dataSet) >= 0){
        possible += '![]{}()%&*$#^<>~@|';
    }
    var text = '';
    for(var i=0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}