require('./bootstrap');
require('codemirror');
require('admin-lte');
require('summernote');
require('bootstrap4-show-password');
require('./backend-modules/generate-password.js');

require('../../public/vendor/laravel-filemanager/js/stand-alone-button.js');

$('.wysiwyg').summernote({
	height: 250,
	toolbar: [
		['style', ['bold', 'italic', 'underline', 'clear']],
		['font', ['strikethrough', 'superscript', 'subscript']],
		['fontsize', ['fontsize', 'color']],
		['para', ['ul', 'ol', 'paragraph']],
		['popovers', ['link', 'table', 'hr']],
        ['view', ['fullscreen', 'codeview', 'help']]
      ]
});

$('#addLine').click(function(e) {
	e.preventDefault();
	let target = $(this).data('target');
	let copy = $(this).data('copy');
	$(target).append($(copy).val());
});

var route_prefix = "/laravel-filemanager";
$('.lfm').filemanager('image', {prefix: route_prefix});
$('#lfm').filemanager('image', {prefix: route_prefix});
$('#lfm2').filemanager('image', {prefix: route_prefix});

$('#theform #tree_type').change(function(e) {
	let val = $(this).val();
	$('#theform .panel').addClass('d-none');
	$('#theform #show-'+val).removeClass('d-none');
});
$('#theform #tree_type').trigger('change');
